const LoggedIn = () => {
  return (
    <div className='container'>
      <div className='paragraph-container'>
        <h2>Hurray, you've just done your first passwordless login!</h2>
      </div>
    </div>
  );
};

export default LoggedIn;
